import { solidstate } from ":shared/utils/state-manager";
import { For, Match, Show, Switch, createMemo } from "solid-js";
import { getTeams } from "./apis/get-teams";
import { TableTeamMembers } from "./tables/team-members";
import { TableRecievedRequests } from "./tables/recieved-requests";
import { TableSentRequests } from "./tables/sent-requests";
import { Loader2 } from ":src/components/loaders";

const tabs = [
  {
    key: "team_members",
    name: "Team Member",
  },
  {
    key: "received_requests",
    name: "Recieved Requests",
  },
  {
    key: "sent_requests",
    name: "Sent Requests",
  },
];
export default function MyTeam() {
  const $active_key = solidstate.create(tabs[0].key);
  const $teams = solidstate.createAsync(getTeams);
  // const $member_type = createMemo(() => {
  //   const s = $teams.value.
  // })
  return (
    <section class="flex flex-col w-full h-full overflow-y-hidden overflow-x-hidden md:(px-5 py-3) <md:(px-3 py-2)">
      {/* title and tabs */}
      <div class="flex flex-row  flex-wrap min-w-full justify-between py-2 <md:(!flex-col) ">
        <h1 class="text-1.1rem whitespace-nowrap capitalize <md:text-1rem">
          Team Members{$teams.value?.role && ` (as ${$teams.value?.role})`}
        </h1>
        <div class="flex flex-row  justify-between  <md:(py-2) <xs:(flex-wrap)">
          <For each={tabs}>
            {(item, index) => {
              const $count = createMemo(() => $teams.value?.[item.key]?.length);
              const $active = createMemo(() => item.key === $active_key.value);
              const $hidden = createMemo(() => {
                if ($teams.value?.team_members?.length > 0) {
                  if (item.key === "sent_requests") {
                    return true;
                  }
                }
                return false;
              });
              return (
                <Show when={!$hidden()}>
                  <p
                    class={`
                  flex flex-row  h-2rem  items-center justify-between rounded-lg cursor-pointer 
                  text-0.6rem px-2 space-x-1
                  <md:(text-0.5rem whitespace-nowrap !h-1.4rem !px-1 !rounded-md)
                  ${$active() ? "bg#a text#pt" : ""}
                  `}
                    onclick={() => {
                      $active_key.set(item.key as any);
                    }}
                  >
                    <span class="flex-1">{item.name}</span>
                    {$count() && (
                      <span
                        class={`flex min-w-0.9rem min-h-0.9rem rounded-full  items-center justify-center 
                      text-0.5rem
                      <md:(text-0.47rem min-w-0.7rem min-h-0.5rem)
                      ${$active() ? "bg#pt text#a" : "bg#a text#pt"}  
                      `}
                      >
                        {$count()}
                      </span>
                    )}
                  </p>
                </Show>
              );
            }}
          </For>
        </div>
      </div>
      {/* list -- whatever it is */}
      <Switch>
        <Match when={$teams.state.loading}>
          <Loader2 msg="Loading Memebrs And Requests..." />
        </Match>
        <Match when={$teams.state.failed}>error occured while loading memebres {$teams.error}</Match>
        <Match when={$teams.state.success}>
          <Switch>
            <Match when={$active_key.value === "team_members"}>
              <TableTeamMembers teams={$teams as any} />
            </Match>
            <Match when={$active_key.value === "received_requests"}>
              <TableRecievedRequests teams={$teams as any} />
            </Match>
            <Match when={$active_key.value === "sent_requests"}>
              <TableSentRequests teams={$teams as any} />
            </Match>
          </Switch>
        </Match>
      </Switch>
    </section>
  );
}
